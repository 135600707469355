import React, { useEffect, useRef } from "react";
import cx from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { SanityImage } from "@components/sanity/sanityImage";

const ImageCarouselGrid = ({
  config,
  topImage,
  bottomImage,
  heading,
  copy,
  carouselImages,
}) => {
  const [activeImage, setActiveImage] = React.useState(0);

  const options = {
    containScroll: true,
    align: "start",
    dragFree: false,
    loop: true,
  };

  const autoplay = useRef(
    Autoplay(
      { delay: 5000, stopOnInteraction: false },
      (emblaRoot) => emblaRoot?.parentElement
    )
  );

  const [viewportRef, embla] = useEmblaCarousel(options, [autoplay?.current]);

  useEffect(() => {
    if (!embla) return;
    embla.scrollTo(activeImage);
  }, [activeImage]);

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setActiveImage(embla.selectedScrollSnap());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <ModuleWrapper {...config} className="text-center">
      <div>
        <div className="px-gutter">
          <h2 className="richH0 my-half text-red">{heading}</h2>
          {copy && (
            <PortableTextBlock text={copy} className="mt-12 md:px-gutter" />
          )}
        </div>
        <div className="mt-24 grid grid-cols-14 gap-x-2 gap-y-2">
          <div className="col-start-2 col-end-14 md:col-end-7 row-span-2 flex flex-col gap-y-2">
            <div className="rounded-lg overflow-hidden">
              <SanityImage image={topImage} />
            </div>
            <div className="rounded-lg overflow-hidden">
              {" "}
              <SanityImage image={bottomImage} />
            </div>
          </div>
          <div className="col-start-2 md:col-start-7 col-end-14 row-span-2 relative overflow-hidden">
            <div ref={viewportRef} className="w-full h-full">
              <div className="flex flex-row w-full h-full">
                {carouselImages?.map((img, i) => (
                  <div key={i} className="w-full" style={{ flex: "0 0 auto" }}>
                    <div className="relative w-full h-full rounded-lg overflow-hidden">
                      <SanityImage
                        key={i}
                        image={img}
                        className="md:absolute top-0 left-0 w-full h-full object-cover"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" absolute bottom-6 md:bottom-8 flex items-center justify-center w-full gap-x-3">
              {carouselImages.map((img, i) => (
                <div
                  key={i}
                  onClick={() => setActiveImage(i)}
                  className="flex h-6 w-6 items-center justify-center cursor-pointer"
                >
                  <div
                    className={cx(
                      "rounded-full transition-all ease-in-out duration-300",
                      {
                        "h-2 w-2 bg-red-light": i !== activeImage,
                        "h-4 w-4 bg-red": i === activeImage,
                      }
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ImageCarouselGrid;
